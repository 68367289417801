.leaflet-container {
  height: 100%;
  position: relative;
  z-index: 1;
}

.leaflet-control-attribution > a[href='https://leafletjs.com'],
.leaflet-control-attribution > span {
  display: none;
}
